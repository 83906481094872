/*
* Credits Page and Author COmponent CSS
*/

@font-face {
    font-family: "PressStart2P-Regular";
    src: url("../PressStart2P-Regular.ttf");
  }
  .PressStart2P {
    font-family: "PressStart2P-Regular";
  }

@media only screen and (min-width: 1208px) {
    /* styles for devices with a minimum screen width of 1208px */

    .blogPostGalleryBlock {
      display: flex;
      justify-content: center;
      max-width: 30%;
    }

    .blogBlock {
        align-content: center;
        background-color: black;
        color: #ffc000;
        min-height: 30vh;
        width: auto;
        min-width: 50%;
        display: inline-block;
        align-items: center;
        justify-content: center;
        border-color: #ffc000;
        border-width: 5px;
        border-top-style: solid;
        border-right-style: solid;
        border-left-style: solid;
        border-bottom-style: solid;
        font-family: "PressStart2P-Regular";
        margin-bottom: 5vh;
      }

      .blogBlockParaHolder {
        align-content: center;
        background-color: black;
        color: #ffc000;
        min-height: 30vh;
        width: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "PressStart2P-Regular";
      }

    .blogBlockPara {
        color: #ffffff;
        margin: 20px;
      }

      .readMoreButton {
        color: black;
        background-color: #ffc000;
        border: dashed;
        border-color: #ffc000;
        padding: 15px 32px;
        margin-inline: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 18px;
        font-family: "PressStart2P-Regular";
        margin-bottom: 5vh;
        margin-top: 5vh;
      }
    
      .readMoreButton:hover {
        background-color: white; /* Green */
        color: black;
        border: dashed;
        border-color: black;
      }

  }
    
    @media only screen and (max-width: 1208px) {
    /* styles for devices with a maximum screen width of 1208px */
  
    .blogBlock {
      align-content: center;
      background-color: black;
      color: #ffc000;
      min-height: 30vh;
      width: auto;
      min-width: 50%;
      display: inline-block;
      align-items: center;
      justify-content: center;
      border-color: #ffc000;
      border-width: 5px;
      border-top-style: solid;
      border-right-style: solid;
      border-left-style: solid;
      border-bottom-style: solid;
      font-family: "PressStart2P-Regular";
      margin-bottom: 5vh;
    }

    .blogBlockParaHolder {
      align-content: center;
      background-color: black;
      color: #ffc000;
      min-height: 30vh;
      width: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "PressStart2P-Regular";
    }

  .blogBlockPara {
      color: #ffffff;
      margin: 20px;
    }

    .readMoreButton {
      color: black;
      background-color: #ffc000;
      border: dashed;
      border-color: #ffc000;
      padding: 15px 32px;
      margin-inline: 5px;
      margin-top: 5px;
      margin-bottom: 5px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 18px;
      font-family: "PressStart2P-Regular";
      margin-bottom: 5vh;
      margin-top: 5vh;
    }
  
    .readMoreButton:hover {
      background-color: white; /* Green */
      color: black;
      border: dashed;
      border-color: black;
    }
  
  }

  @media only screen and (max-width: 500px) {
    /* styles for devices with a maximum screen width of 1208px */
  
    .blogBlock {
      align-content: center;
      background-color: black;
      color: #ffc000;
      min-height: 30vh;
      width: auto;
      display: inline-block;
      align-items: center;
      justify-content: center;
      border-color: #ffc000;
      border-width: 5px;
      border-top-style: solid;
      border-right-style: solid;
      border-left-style: solid;
      border-bottom-style: solid;
      font-family: "PressStart2P-Regular";
      margin-bottom: 5vh;
    }

    .blogBlockParaHolder {
      align-content: center;
      background-color: black;
      color: #ffc000;
      min-height: 30vh;
      width: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "PressStart2P-Regular";
    }

  .blogBlockPara {
      color: #ffffff;
      margin: 5px;
    }

    .readMoreButton {
      color: black;
      background-color: #ffc000;
      border: dashed;
      border-color: #ffc000;
      padding: 15px 32px;
      margin-inline: 5px;
      margin-top: 5px;
      margin-bottom: 5px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 18px;
      font-family: "PressStart2P-Regular";
      margin-bottom: 5vh;
      margin-top: 5vh;
    }
  
    .readMoreButton:hover {
      background-color: white; /* Green */
      color: black;
      border: dashed;
      border-color: black;
    }
  
  }