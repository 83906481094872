/**
 * Main CSS Template
 * @author Michal Tvrdy
 */

@media only screen and (min-width: 1348px) {
  /* styles for devices with a minimum screen width of 1208px */
  
  .mainMenu {
    margin-top: 5px;
    background-color: black;
    display: flex;
    align-content: center;
    justify-content: center;
    justify-items: center;
    color: #fcd000;
    width: 50%;
    outline: 5px solid #ffc000;
    font-family: "PressStart2P-Regular";
    min-height: 5vh;
  }

  .mainMenuOptionBox {
    display: flex;
    align-content: center;
    justify-content: center;
    justify-items: center;
    font-family: "PressStart2P-Regular";
  }
  
  .mainMenuLinks {
    color: #b30c00;
    font-size: 20px;
    margin: 5px;
    vertical-align: middle;
    font-family: "PressStart2P-Regular";
  }

  .basicTextBox {
    align-content: center;
    background-color: black;
    color: #ffc000;
    min-height: 30vh;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-color: black;
    border-width: 5px;
    border-right-style: solid;
    border-left-style: solid;
    font-family: "PressStart2P-Regular";
  }
  
  .basicBlock {
    align-content: center;
    background-color: black;
    color: #ffc000;
    min-height: 30vh;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    outline: 5px solid #fcd000;
    font-family: "PressStart2P-Regular";
  }
  
  .ourGamesBlock {
    color: white;
    min-height: 30vh;
    width: 50%;
    display:flex;
    font-family: "PressStart2P-Regular";
  }

  .ourGamesBlock2 {
    justify-content: center;
    color: white;
    min-height: 30vh;
    width: 50%;
    display:flex;
    font-family: "PressStart2P-Regular";
  }

  .ourGamesGameBlock {
    vertical-align: top;
    min-width: 300px;
    max-width: 300px;
  }

  .ourGamesMainBlock {
    align-content: center;
    display: inline;
    align-items: center;
    justify-content: center;
  }

  .ourGamesMainBlockTitle {
    color: #b9001e;
    font-family: "PressStart2P-Regular";
    padding-top: 5vh;
    margin-top: 5vh;
    margin-bottom: 5vh;
  }

  .ourGamesGameTitle {
    color: #ffc000;
  }

  .authorsComponentBlock {
    background-color: #ffc000;
    color: black;
    min-height: 30vh;
    width: 50%;
    display: flex;
    justify-content: center;
    border-color: #ffc000;
    border-width: 5px;
    border-right-style: solid;
    border-left-style: solid;
    font-family: "PressStart2P-Regular";
  }

  /*
  .blogBlock {
    align-content: center;
    background-color: black;
    color: #ffc000;
    min-height: 30vh;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-color: #ffc000;
    border-width: 5px;
    border-top-style: solid;
    border-right-style: solid;
    border-left-style: solid;
    font-family: "PressStart2P-Regular";
  }
  */
  
  .red {
    text-align: center;
    font-size: 30px;
    font-family: "PressStart2P-Regular";
    color: #b30c00;
  }
  
  .footerBlock {
    align-content: center;
    background-color: #ffc000;
    color: black;
    min-height: 10vh;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-color: #ffc000;
    border-width: 5px;
    border-top-style: solid;
    border-right-style: solid;
    border-left-style: solid;
    font-family: "PressStart2P-Regular";
  }

  .findOutMoreButton {
    color: #ffc000;
    background-color: black;
    border: dashed;
    border-color: #ffc000;
    padding: 15px 32px;
    margin-inline: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 21px;
    font-family: "PressStart2P-Regular";
    margin-bottom: 7vh;
    margin-top: 7vh;
  }

  .findOutMoreButton:hover {
    background-color: white; /* Green */
    color: black;
    border: dashed;
    border-color: black;
  }

}
  
  @media only screen and (max-width: 1348px) {
  /* styles for devices with a maximum screen width of 1208px */
  
  .mainMenu {
    margin-top: 5px;
    background-color: black;
    display: inline;
    align-content: center;
    justify-content: center;
    justify-items: center;
    color: #fcd000;
    width: 100%;
    font-family: "PressStart2P-Regular";
    min-height: 5vh;
  }

  .mainMenuOptionBox {
    display: flex;
    align-content: center;
    justify-content: center;
    justify-items: center;
    font-family: "PressStart2P-Regular";
  }
  
  .mainMenuLinks {
    color: #b30c00;
    font-size: 20px;
    margin: 5px;
    vertical-align: middle;
    font-family: "PressStart2P-Regular";
  }

  .basicTextBox {
    align-content: center;
    background-color: black;
    color: #ffc000;
    min-height: 30vh;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-color: black;
    border-width: 5px;
    border-right-style: solid;
    border-left-style: solid;
    font-family: "PressStart2P-Regular";
  }
  
  .basicBlock {
    align-content: center;
    background-color: black;
    color: #ffc000;
    min-height: 30vh;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    outline: 5px solid #fcd000;
    font-family: "PressStart2P-Regular";
  }


  /* Check our Games */

  .ourGamesBlock {
    color: white;
    font-family: "PressStart2P-Regular";
  }

  .ourGamesBlock2 {
    color: white;
    font-family: "PressStart2P-Regular";
  }

  .ourGamesMainBlockTitle {
    color: #b9001e;
    font-family: "PressStart2P-Regular";
    padding-top: 5vh;
    margin-top: 5vh;
    margin-bottom: 5vh;
  }

  .ourGamesGameTitle {
    color: #ffc000;
  }

  /* Authors */

  .authorsComponentBlock {
    background-color: #ffc000;
    color: black;
    min-height: 30vh;
    width: 50%;
    display: flex;
    justify-content: center;
    border-color: #ffc000;
    border-width: 5px;
    border-right-style: solid;
    border-left-style: solid;
    font-family: "PressStart2P-Regular";
  }
  
  .red {
    text-align: center;
    font-size: 30px;
    font-family: "PressStart2P-Regular";
    color: #b30c00;
  }
  
  .footerBlock {
    align-content: center;
    background-color: #ffc000;
    color: black;
    min-height: 10vh;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-color: #ffc000;
    border-width: 5px;
    border-top-style: solid;
    border-right-style: solid;
    border-left-style: solid;
    font-family: "PressStart2P-Regular";
  }

  .findOutMoreButton {
    color: #ffc000;
    background-color: black;
    border: dashed;
    border-color: #ffc000;
    padding: 15px 32px;
    margin-inline: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 21px;
    font-family: "PressStart2P-Regular";
    margin-bottom: 7vh;
    margin-top: 7vh;
  }

  .findOutMoreButton:hover {
    background-color: white; /* Green */
    color: black;
    border: dashed;
    border-color: black;
  }

}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@font-face {
  font-family: "PressStart2P-Regular";
  src: url("./PressStart2P-Regular.ttf");
}
.PressStart2P {
  font-family: "PressStart2P-Regular";
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Section-component {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
