/*
* Main Menu Page CSS
*/

@font-face {
    font-family: "PressStart2P-Regular";
    src: url("../PressStart2P-Regular.ttf");
  }
  .PressStart2P {
    font-family: "PressStart2P-Regular";
  }

@media only screen and (min-width: 1208px) {
    /* styles for devices with a minimum screen width of 1208px */

    .mainMenu {
        margin-top: 5px;
        background-color: black;
        display: flex;
        align-content: center;
        justify-content: center;
        justify-items: center;
        color: #fcd000;
        width: 50%;
        outline: 5px solid #ffc000;
        font-family: "PressStart2P-Regular";
        min-height: 5vh;
      }
        
    .buttonMenu {
        color: black;
        background-color: #ffc000;
        border: none;
        margin-inline: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        font-family: "PressStart2P-Regular";
        width: 100%;
        height: 100%;
        padding: 15px;
    }

    .buttonMenu:hover {
        background-color: white; /* Green */
        color: #b9001e;
    }

    .buttonMenu:focus {
        background-color: white;
        color: #b9001e;
    }

    .buttonMenu:target {
        background-color: white;
        color: #b9001e;
    }

  }
  
  @media only screen and (max-width: 1208px) {
    /* styles for devices with a maximum screen width of 1208px */

    .mainMenu {
        margin-top: 5px;
        background-color: black;
        display: flex;
        align-content: center;
        justify-content: center;
        justify-items: center;
        color: #fcd000;
        width: 100%;
        font-family: "PressStart2P-Regular";
        min-height: 5vh;
        min-width: 400px;
      }

      .buttonMenu {
        color: black;
        background-color: #ffc000;
        border: none;
        margin-inline: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        font-family: "PressStart2P-Regular";
        width: 90%;
        height: 50%;
        padding: 15px 32px;
      }
    
      .buttonMenu:hover {
        background-color: white; /* Green */
        color: #b9001e;
      }
    
      .buttonMenu:focus {
        background-color: white;
        color: #b9001e;
      }
    
      .buttonMenu:target {
        background-color: white;
        color: #b9001e;
      }

  }

    @media only screen and (max-width: 800px) {
    /* styles for devices with a maximum screen width of 1208px */

    .mainMenu {
        margin-top: 5px;
        background-color: black;
        display: inline;
        align-content: center;
        justify-content: center;
        justify-items: center;
        color: #fcd000;
        width: 100%;
        font-family: "PressStart2P-Regular";
        min-height: 5vh;

      }

      .buttonMenu {
        color: black;
        background-color: #ffc000;
        border: none;
        margin-inline: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        font-family: "PressStart2P-Regular";
        width: 90%;
        height: 50%;
        padding: 15px 32px;
      }
    
      .buttonMenu:hover {
        background-color: white; /* Green */
        color: #b9001e;
      }
    
      .buttonMenu:focus {
        background-color: white;
        color: #b9001e;
      }
    
      .buttonMenu:target {
        background-color: white;
        color: #b9001e;
      }

  }
