/*
* Header / Main Title Component CSS
*/

@font-face {
    font-family: "PressStart2P-Regular";
    src: url("../PressStart2P-Regular.ttf");
  }
  .PressStart2P {
    font-family: "PressStart2P-Regular";
  }

@media only screen and (min-width: 1208px) {
    /* styles for devices with a minimum screen width of 1208px */

    .noiseBlock {
        background: 
          repeating-radial-gradient(#000 0 0.0001%,#fff 0 0.0002%) 50% 0/2500px 2500px,
          repeating-conic-gradient(#000 0 0.0001%,#fff 0 0.0002%) 60% 60%/2500px 2500px;
        background-blend-mode: difference;
        animation: b .2s infinite alternate;
        width: 100%;
        height: 100%;
        border-color: black;
        border-style: solid;
        border-width: 10px;
        padding-top: 50px;
        padding-bottom: 50px;
      }
      @keyframes b{
        100% {background-position: 50% 0, 60% 50%}
      }
      
      .mainTitle {
        font-size: 48px;
        color: #b9001e;
      }

    .basicTitleBox {
        align-content: center;
        color: #ffc000;
        min-height: 30vh;
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-family: "PressStart2P-Regular";
      }

  }
    
    @media only screen and (max-width: 1208px) {
    /* styles for devices with a maximum screen width of 1208px */

    .noiseBlock {
        width: 100%;
        height: 100%;
      }
    
      .mainTitle {
        font-size: 48px;
        color: #b9001e;
      }
    
    .basicTitleBox {
        align-content: center;
        color: #ffc000;
        min-height: 30vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-family: "PressStart2P-Regular";
      }

  }

  @media only screen and (max-width: 500px) {
    /* styles for devices with a maximum screen width of 500px */

    .noiseBlock {
        width: 100%;
        height: 100%;
      }
    
      .mainTitle {
        font-size: 36px;
        color: #b9001e;
      }
    
    .basicTitleBox {
        align-content: center;
        color: #ffc000;
        min-height: 30vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-family: "PressStart2P-Regular";
      }

  }