/*
* Game Announcement Cmponent CSS
*/

@font-face {
    font-family: "PressStart2P-Regular";
    src: url("../PressStart2P-Regular.ttf");
  }
  .PressStart2P {
    font-family: "PressStart2P-Regular";
  }

@media only screen and (min-width: 1208px) {
    /* styles for devices with a minimum screen width of 1208px */

    .videoWrapper {
      width: 560px;
      height: 315px;
      border-style: solid;
      border-color: black;
      background-color: black;
    }

    .gameAnnouncementBlogBlockPara {
        color: black;
        margin: 20px;
        text-align: center;
      }
      
      .gameAnnouncementBlock {
        align-content: center;
        background-color: #ffc000;
        color: black;
        min-height: 30vh;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-color: #ffc000;
        border-width: 5px;
        border-right-style: solid;
        border-left-style: solid;
        font-family: "PressStart2P-Regular";
      }
    
      @keyframes textShine {
        0% {
            background-position: 0% 50%;
        }
        100% {
            background-position: 100% 50%;
        }
    }
    
      .gameAnnouncementTitle {
        background: linear-gradient(
          to right,
          #b9001e 20%,
          #000000 30%,
          #000000 70%,
          #b9001e 80%
      );
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      text-fill-color: transparent;
      background-size: 500% auto;
      animation: textShine 5s ease-in-out infinite alternate;
      }
    
      .gameAnnouncementInblock {
        align-content: center;
        display: inline;
        align-items: center;
        justify-content: center;
        min-width: min-content;
        font-family: "PressStart2P-Regular";
      }
    
      .gameAnnouncementVideoBlock {
        border-color: black;
        border-width: 10px;
        border-style: solid;
        width: 560px;
        height: 315px;
      }
    
      .gameAnnouncementDownloadBlock {
        align-content: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px;
      }


      .downloadButton {
        color: #ffc000;
        background-color: black;
        border: dashed;
        border-color: #ffc000;
        padding: 15px 32px;
        margin-inline: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 21px;
        font-family: "PressStart2P-Regular";
        margin-bottom: 7vh;
        margin-top: 7vh;
      }
    
      .downloadButton:hover {
        background-color: white; /* Green */
        color: black;
        border: dashed;
        border-color: black;
      }

  }
    
    @media only screen and (max-width: 1208px) {
    /* styles for devices with a maximum screen width of 1208px */
  
    .gameAnnouncementBlogBlockPara {
        color: black;
        margin: 20px;
        text-align: block;
      }
      
      .gameAnnouncementBlock {
        align-content: center;
        background-color: #ffc000;
        color: black;
        min-height: auto;
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-color: #ffc000;
        border-width: 5px;
        border-right-style: solid;
        border-left-style: solid;
        font-family: "PressStart2P-Regular";
        min-width: 90%;
      }
    
      @keyframes textShine {
        0% {
            background-position: 0% 50%;
        }
        100% {
            background-position: 100% 50%;
        }
    }
    
      .gameAnnouncementTitle {
        background: linear-gradient(
          to right,
          #b9001e 20%,
          #000000 30%,
          #000000 70%,
          #b9001e 80%
      );
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      text-fill-color: transparent;
      background-size: 500% auto;
      animation: textShine 5s ease-in-out infinite alternate;
      }
    
      .gameAnnouncementInblock {
        align-content: center;
        display: inline;
        align-items: center;
        justify-content: center;
        min-width:min-content;
        font-family: "PressStart2P-Regular";
      }
    
      .videoWrapper {
        width: 90%;
        height: 90%;
        border-style: solid;
        border-color: black;
        background-color: black;
      }
    
      .gameAnnouncementVideoBlock {
        border-color: black;
        border-width: 10px;
        border-style: solid;
      }
    
      .gameAnnouncementDownloadBlock {
        align-content: center;
        display: inline;
        align-items: center;
        justify-content: center;
        margin: 10px;
      }
    
      .downloadButton {
        color: #ffc000;
        background-color: black;
        border: dashed;
        border-color: #ffc000;
        padding: 15px 32px;
        margin-inline: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 21px;
        font-family: "PressStart2P-Regular";
        margin-bottom: 3vh;
        margin-top: 3vh;
      }
    
      .downloadButton:hover {
        background-color: white; /* Green */
        color: black;
        border: dashed;
        border-color: black;
      }


  }

  @media only screen and (max-width: 500px) {
    /* styles for devices with a maximum screen width of 1208px */

    .gameAnnouncementBlogBlockPara {
      color: black;
      text-align: center;
    }
    
    .gameAnnouncementBlock {
      align-content: center;
      background-color: #ffc000;
      color: black;
      min-height: auto;
      width: 100%;
      display: inline;
      align-items: center;
      justify-content: center;
      font-family: "PressStart2P-Regular";
    }
  
    @keyframes textShine {
      0% {
          background-position: 0% 50%;
      }
      100% {
          background-position: 100% 50%;
      }
  }
  
    .gameAnnouncementTitle {
      background: linear-gradient(
        to right,
        #b9001e 20%,
        #000000 30%,
        #000000 70%,
        #b9001e 80%
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    background-size: 500% auto;
    animation: textShine 5s ease-in-out infinite alternate;
    }
  
    .gameAnnouncementInblock {
      align-content: center;
      display: inline;
      align-items: center;
      justify-content: center;
      font-family: "PressStart2P-Regular";
    }
  
    .videoWrapper {
      border-style: solid;
      border-color: black;
      background-color: black;
    }
  
    .gameAnnouncementVideoBlock {
      border-color: black;
    }
  
    .gameAnnouncementDownloadBlock {
      align-content: center;
      display: inline;
      align-items: center;
      justify-content: center;
    }
  
    .downloadButton {
      color: #ffc000;
      background-color: black;
      border: dashed;
      border-color: #ffc000;
      padding: 1px 1px;
      text-align: center;
      text-decoration: none;
    }
  
    .downloadButton:hover {
      background-color: white; /* Green */
      color: black;
      border: dashed;
      border-color: black;
    }


  }