/*
* Support Us Page CSS
*/

@font-face {
    font-family: "PressStart2P-Regular";
    src: url("../PressStart2P-Regular.ttf");
  }
  .PressStart2P {
    font-family: "PressStart2P-Regular";
  }

@media only screen and (min-width: 1208px) {
    /* styles for devices with a minimum screen width of 1208px */

    .supportUsContentBlock {
        margin-top: 5vh;
        padding-bottom: 3vh;
        width: 50%;
        border:#ffc000;
        border-style: solid;
        background-color: black;
        align-content: center;
        align-items: center;
        font-family: "PressStart2P-Regular";
    }

    .supportUsTitle {
        color: #ffc000;
    }

    .supportUsParagraph {
        color: white;
    }

    .megahateSoundtrackBlock {
        display: inline;
        border-style: solid;
        border-color: #ffc000;
        border-width: 5px;
      }
    
      .megahateSoundtrackInframe {
        border: 0px;
        width: 400px;
        height: 406px;
      }

      .supportUsButton {
        color: black;
        background-color: #ffc000;
        border: dashed;
        border-color: black;
        padding: 15px 32px;
        margin-inline: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
        text-align: center;
        text-decoration: none;
        font-size: 21px;
        font-family: "PressStart2P-Regular";
        margin-bottom: 5vh;
        margin-top: 5vh;
      }
    
      .supportUsButton:hover {
        background-color: rgb(9, 204, 51); /* Green */
        color: black;
        border: dashed;
        border-color: black;
      }

      .supportUsButtonBlock {
        margin-top: 5vh;
        margin-bottom: 3vh;
      }

  }
    
    @media only screen and (max-width: 1208px) {
    /* styles for devices with a maximum screen width of 1208px */
    .supportUsContentBlock {
        margin-top: 5vh;
        min-width:min-content;
        padding-bottom: 3vh;
        width: 70%;
        border:#ffc000;
        border-style: solid;
        background-color: black;
        align-content: center;
        align-items: center;
        font-family: "PressStart2P-Regular";
    }

    .supportUsTitle {
        color: #ffc000;
    }

    .supportUsParagraph {
        color: white;
    }

    .megahateSoundtrackBlock {
        display: inline;
      }
    
      .megahateSoundtrackInframe {
        border: 0px;
        width: 400px;
        height: 406px;
      }
  
      .supportUsButton {
        color: black;
        background-color: #ffc000;
        border: dashed;
        border-color: black;
        padding: 15px 32px;
        margin-inline: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
        text-align: center;
        text-decoration: none;
        font-size: 21px;
        font-family: "PressStart2P-Regular";
        margin-bottom: 5vh;
        margin-top: 5vh;
      }
    
      .supportUsButton:hover {
        background-color: rgb(9, 204, 51); /* Green */
        color: black;
        border: dashed;
        border-color: black;
      }

      .supportUsButtonBlock {
        margin-top: 5vh;
        margin-bottom: 3vh;
      }

  }

  @media only screen and (max-width: 500px) {
    /* styles for devices with a maximum screen width of 1208px */
    .supportUsContentBlock {
        margin-top: 5vh;
        padding-bottom: 3vh;
        width: 90%;
        border:#ffc000;
        border-style: solid;
        background-color: black;
        align-content: center;
        align-items: center;
        font-family: "PressStart2P-Regular";
    }

    .supportUsTitle {
        color: #ffc000;
    }

    .supportUsParagraph {
        color: white;
    }

    .megahateSoundtrackBlock {
        display: inline;
      }
    
      .megahateSoundtrackInframe {
        width: 300px;
        height: auto;
      }
  
      .supportUsButton {
        color: black;
        background-color: #ffc000;
        border: dashed;
        border-color: black;
        padding: 15px 16px;
        margin-inline: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
        text-align: center;
        text-decoration: none;
        font-size: 21px;
        font-family: "PressStart2P-Regular";
        margin-bottom: 5vh;
        margin-top: 5vh;
      }
    
      .supportUsButton:hover {
        background-color: rgb(9, 204, 51); /* Green */
        color: black;
        border: dashed;
        border-color: black;
      }

      .supportUsButtonBlock {
        margin-top: 5vh;
        margin-bottom: 3vh;
      }

  }