/*
* Merch Page CSS
*/

@font-face {
    font-family: "PressStart2P-Regular";
    src: url("../PressStart2P-Regular.ttf");
  }
  .PressStart2P {
    font-family: "PressStart2P-Regular";
  }

@media only screen and (min-width: 1208px) {
    /* styles for devices with a minimum screen width of 1208px */
    .underConstructionBlock {
        align-content: center;
        background-color: #ffc000;
        color: black;
        min-height: 30vh;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-color: black;
        border-width: 5px;
        border-right-style: dashed;
        border-left-style: dashed;
        border-top-style: dashed;
        border-bottom-style: dashed;
        font-family: "PressStart2P-Regular";
        margin-bottom: 10vh;
      }
    
      .underConstructionTitle {
        color: #b9001e;
      }
    
      .underConstructionInblock {
        align-content: center;
        display: inline;
        align-items: center;
        justify-content: center;
        font-family: "PressStart2P-Regular";
      }

  }
    
    @media only screen and (max-width: 1208px) {
    /* styles for devices with a maximum screen width of 1208px */
      .underConstructionBlock {
        align-content: center;
        background-color: #ffc000;
        color: black;
        min-height: 30vh;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-color: black;
        border-width: 5px;
        border-right-style: dashed;
        border-left-style: dashed;
        border-top-style: dashed;
        border-bottom-style: dashed;
        font-family: "PressStart2P-Regular";
        margin-bottom: 10vh;
      }
    
      .underConstructionTitle {
        color: #b9001e;
      }
    
      .underConstructionInblock {
        align-content: center;
        display: inline;
        align-items: center;
        justify-content: center;
        font-family: "PressStart2P-Regular";
      }
  
  }

  @media only screen and (max-width: 500px) {
    /* styles for devices with a maximum screen width of 1208px */
      .underConstructionBlock {
        align-content: center;
        background-color: #ffc000;
        color: black;
        min-height: 30vh;
        width: 100%;
        align-items: center;
        justify-content: center;
        border-color: black;
        border-width: 0px;
        border-right-style: dashed;
        border-left-style: dashed;
        border-top-style: dashed;
        border-bottom-style: dashed;
        font-family: "PressStart2P-Regular";
        margin-bottom: 10vh;
      }
    
      .underConstructionTitle {
        color: #b9001e;
        font-size: 18pt;
      }
    
      .underConstructionInblock {
        align-content: center;
        display: inline;
        align-items: center;
        justify-content: center;
        font-family: "PressStart2P-Regular";
      }
  
  }