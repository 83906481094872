/*
* BlogFeed Component CSS
*/

@font-face {
    font-family: "PressStart2P-Regular";
    src: url("../PressStart2P-Regular.ttf");
  }
  .PressStart2P {
    font-family: "PressStart2P-Regular";
  }

@media only screen and (min-width: 1208px) {
    /* styles for devices with a minimum screen width of 1208px */

    .blogfeedComponentMainBlock {
      margin-top: 5vh;
      margin-bottom: 5vh;
      width: 50%;
      font-family: "PressStart2P-Regular";
      border-top-style: solid;
      border-bottom-style: solid;
      border-color: #ffc000;
      border-width: 5px;
    }

    .blogfeedComponentTitle {
        color: #b9001e;
    }

    .blogfeedButton {
      color: #ffc000;
      background-color: black;
      border: dashed;
      border-color: #ffc000;
      padding: 15px 32px;
      margin-inline: 5px;
      margin-top: 5px;
      margin-bottom: 5px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 21px;
      font-family: "PressStart2P-Regular";
      margin-bottom: 5vh;
      margin-top: 5vh;
    }
  
    .blogfeedButton:hover {
      background-color: white; /* Green */
      color: black;
      border: dashed;
      border-color: black;
    }

  }
    
    @media only screen and (max-width: 1208px) {
    /* styles for devices with a maximum screen width of 1208px */
  
    .blogfeedComponentMainBlock {
      min-width: min-content;
      margin-top: 5vh;
      margin-bottom: 5vh;
      width: 90%;
      font-family: "PressStart2P-Regular";
      border-top-style: solid;
      border-bottom-style: solid;
      border-color: #ffc000;
      border-width: 5px;
    }

    .blogfeedComponentTitle {
        color: #b9001e;
    }

    .blogfeedButton {
      color: #ffc000;
      background-color: black;
      border: dashed;
      border-color: #ffc000;
      padding: 15px 32px;
      margin-inline: 5px;
      margin-top: 5px;
      margin-bottom: 5px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 21px;
      font-family: "PressStart2P-Regular";
      margin-bottom: 5vh;
      margin-top: 5vh;
    }
  
    .blogfeedButton:hover {
      background-color: white; /* Green */
      color: black;
      border: dashed;
      border-color: black;
    }
  
  }

  @media only screen and (max-width: 500px) {
    /* styles for devices with a maximum screen width of 1208px */
  
    .blogfeedComponentMainBlock {
      min-width: min-content;
      margin-top: 5vh;
      margin-bottom: 5vh;
      width: 90%;
      font-family: "PressStart2P-Regular";
      border-top-style: solid;
      border-bottom-style: solid;
      border-color: #ffc000;
      border-width: 5px;
    }

    .blogfeedComponentTitle {
        color: #b9001e;
    }

    .blogfeedButton {
      color: #ffc000;
      background-color: black;
      border: dashed;
      border-color: #ffc000;
      padding: 15px 32px;
      margin-inline: 5px;
      margin-top: 5px;
      margin-bottom: 5px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 21px;
      font-family: "PressStart2P-Regular";
      margin-bottom: 5vh;
      margin-top: 5vh;
    }
  
    .blogfeedButton:hover {
      background-color: white; /* Green */
      color: black;
      border: dashed;
      border-color: black;
    }
  
  }