/*
* Credits Page and Author COmponent CSS
*/

@font-face {
    font-family: "PressStart2P-Regular";
    src: url("../PressStart2P-Regular.ttf");
  }
  .PressStart2P {
    font-family: "PressStart2P-Regular";
  }

@media only screen and (min-width: 1208px) {
    /* styles for devices with a minimum screen width of 1208px */

    .authorsComponentMainBlock {
      width: 50%;
      background-color: #ffc000;
      border-color: black;
      border-width: 5px;
      border-right-style: solid;
      border-left-style: solid;
      border-top-style: solid;
      border-bottom-style: solid;
      margin-top: 5vh;
      margin-bottom: 5vh;
    }

    .authorsComponentBlock {
      vertical-align: top;
      background-color: #ffc000;
      color: black;
      min-height: 30vh;
      width: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "PressStart2P-Regular";
    }

    .authorsBlock {
      vertical-align: top;
      display: flex;
    }

    .authorsComponentBlockTitle {
      color:#b9001e;
      font-family: "PressStart2P-Regular";
    }

    .authorSectionComponentBlock {
        vertical-align:top;
        align-items: baseline;
        background-color: #ffc000;
        color: black;
        width: 50%;
        font-family: "PressStart2P-Regular";
      }

    .authorsComponentTitleBlock {
        color: black;
        background-color: #ffc000;
        font-family: "PressStart2P-Regular";
    }

    .authorsComponentPara {
        color: black;
    }

    .creditsPageComponentBlock {
      width: 50%;
    }

    .authorsComponentTitle {
        color: black;
    }

    .contactUsButton {
      color: #ffc000;
      background-color: black;
      border: dashed;
      border-color: #ffc000;
      padding: 15px 32px;
      margin-inline: 5px;
      margin-top: 5px;
      margin-bottom: 5px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 21px;
      font-family: "PressStart2P-Regular";
      margin-bottom: 5vh;
      margin-top: 5vh;
    }
  
    .contactUsButton:hover {
      background-color: white; /* Green */
      color: black;
      border: dashed;
      border-color: black;
    }

    .socialMediaBlock {
      display:inline-flex;
      justify-content: center;
      align-items: center;
      margin-top: 5vh;
      margin-bottom: 5vh;
    }

    .socialMediaImage {
      width: 50%;
      height: 50%;
    }

    .socialMediaLink {
      padding: 15px;
      padding-top: 50%;
    }

    .socialMediaLink:hover {
      background-color: black;
    }

    .socialMediaItemBlock {
      width: 100%;
      padding: 5px;
    }

  }
    
    @media only screen and (max-width: 1208px) {
    /* styles for devices with a maximum screen width of 1208px */
  
    .authorsComponentMainBlock {
      width: 70%;
      min-width: min-content;
      background-color: #ffc000;
      border-width: 5px;
      border-right-style: solid;
      border-left-style: solid;
      border-top-style: solid;
      border-bottom-style: solid;
      margin-top: 5vh;
      margin-bottom: 5vh;
    }

    .authorsComponentBlock {
      background-color: #ffc000;
      color: black;
      min-height: 30vh;
      width: auto;
      display: inline-block;
      justify-content: center;
      align-items: center;
      font-family: "PressStart2P-Regular";
    }

    .authorsBlock {
      display: flex;
    }

    .authorsComponentBlockTitle {
      color:#b9001e;
      font-family: "PressStart2P-Regular";
    }

    .authorSectionComponentBlock {
      vertical-align:top;
      align-items: baseline;
      background-color: #ffc000;
      color: black;
      width: 100%;
      font-family: "PressStart2P-Regular";
    }

    .authorsComponentTitleBlock {
        color: black;
        background-color: #ffc000;
        font-family: "PressStart2P-Regular";
    }

    .authorsComponentPara {
        color: black;
    }

    .creditsPageComponentBlock {
      width: 70%;
    }

    .authorsComponentTitle {
        color: black;
    }

    .contactUsButton {
      color: #ffc000;
      background-color: black;
      border: dashed;
      border-color: #ffc000;
      padding: 15px 32px;
      margin-inline: 5px;
      margin-top: 5px;
      margin-bottom: 5px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 21px;
      font-family: "PressStart2P-Regular";
      margin-bottom: 5vh;
      margin-top: 5vh;
    }
  
    .contactUsButton:hover {
      background-color: white; /* Green */
      color: black;
      border: dashed;
      border-color: black;
    }

    .socialMediaBlock {
      display: inline;
      margin-top: 10vh;
      margin-bottom: 10vh;
    }

    .socialMediaImage {
      width: 50%;
      height: 50%;
    }

    .socialMediaLink {
      padding: 20px;
      padding-top: 50%;
    }

    .socialMediaLink:hover {
      background-color: black;
    }

    .socialMediaItemBlock {
      width: 100%;
      padding: 5px;
    }

  }