/*
* Credits Page and Author COmponent CSS
*/

@font-face {
    font-family: "PressStart2P-Regular";
    src: url("../PressStart2P-Regular.ttf");
  }
  .PressStart2P {
    font-family: "PressStart2P-Regular";
  }

@media only screen and (min-width: 1208px) {
    /* styles for devices with a minimum screen width of 1208px */


    .gamePageComponentDivider {
        min-height: 10vh;
    }

    .gameAnnouncementBlock_old {
        align-content: center;
        background-color: #ffc000;
        color: black;
        min-height: 30vh;
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-color: #ffc000;
        border-width: 5px;
        border-right-style: solid;
        border-left-style: solid;
        font-family: "PressStart2P-Regular";
      }
    
      .gameAnnouncementBlogBlockPara {
        color: black;
        margin: 20px;
        text-align: justify;
      }
      
      .gameAnnouncementBlock {
        align-content: center;
        background-color: #ffc000;
        color: black;
        min-height: 30vh;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-color: #ffc000;
        border-width: 5px;
        border-right-style: solid;
        border-left-style: solid;
        font-family: "PressStart2P-Regular";
      }

      .gameAnnouncementBlockTokio {
        align-content: center;
        background-color: #d8d8d8;
        color: black;
        min-height: 30vh;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-color: #d8d8d8;
        border-width: 5px;
        border-right-style: solid;
        border-left-style: solid;
        font-family: "PressStart2P-Regular";
      }

      .gameAnnouncementBlockSpaceZ {
        align-content: center;
        background-color: #6db5c2;
        color: black;
        min-height: 30vh;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-color: #6db5c2;
        border-width: 5px;
        border-right-style: solid;
        border-left-style: solid;
        font-family: "PressStart2P-Regular";
      }
    
      @keyframes textShine {
        0% {
            background-position: 0% 50%;
        }
        100% {
            background-position: 100% 50%;
        }
    }
    
      .gameAnnouncementTitle {
        background: linear-gradient(
          to right,
          #b9001e 20%,
          #000000 30%,
          #000000 70%,
          #b9001e 80%
      );
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      text-fill-color: transparent;
      background-size: 500% auto;
      animation: textShine 5s ease-in-out infinite alternate;
      }
    
      .gameAnnouncementInblock {
        align-content: center;
        display: inline;
        align-items: center;
        justify-content: center;
        font-family: "PressStart2P-Regular";
      }

      .authorsContactComponentBlock {
        align-content: left;
        display: inline;
        align-items: left;
        justify-content: left;
        font-family: "PressStart2P-Regular";
      }

      .gameVideoBlockWrapperBlock {
        width: 100%;
        min-height: 20vh;
        background-color: #000000;
      }
      
      .gameAnnouncementVideoBlock {
        border-color: black;
        border-width: 10px;
        border-style: solid;
        width: 560px;
        height: 315px;
      }
    
      .gameAnnouncementDownloadBlock {
        align-content: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px;
      }
      
      .ourGamesBlock {
        color: white;
        min-height: 30vh;
        width: 50%;
        justify-content: center;
        display:flex;
        font-family: "PressStart2P-Regular";
      }
    
      .ourGamesGameBlock {
        vertical-align: top;
        min-width: 300px;
        max-width: 300px;
      }
    
      .ourGamesMainBlock {
        align-content: center;
        display: inline;
        align-items: center;
        justify-content: center;
      }
    
      .ourGamesMainBlockTitle {
        color: #b9001e;
        width: 50%;
        border-top-style: solid;
        border-width: 5px;
        border-color: #ffc000;
        font-family: "PressStart2P-Regular";
        margin-top: 5vh;
        margin-bottom: 5vh;
      }
    
      .ourGamesGameTitle {
        color: #ffc000;
      }

  }
    
    @media only screen and (max-width: 1208px) {
    /* styles for devices with a maximum screen width of 1208px */
    .gamePageComponentDivider {
      min-height: 10vh;
  }
  
    .gameAnnouncementBlogBlockPara {
      color: black;
      margin: 20px;
      text-align: justify;
    }
    
    .gameAnnouncementBlock {
      align-content: center;
      background-color: #ffc000;
      color: black;
      min-height: 30vh;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-color: #ffc000;
      border-width: 5px;
      border-right-style: solid;
      border-left-style: solid;
      font-family: "PressStart2P-Regular";
    }

    .gameAnnouncementBlockTokio {
      align-content: center;
      background-color: #d8d8d8;
      color: black;
      min-height: 30vh;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-color: #d8d8d8;
      border-width: 5px;
      border-right-style: solid;
      border-left-style: solid;
      font-family: "PressStart2P-Regular";
    }

    .gameAnnouncementBlockSpaceZ {
      align-content: center;
      background-color: #6db5c2;
      color: black;
      min-height: 30vh;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-color: #6db5c2;
      border-width: 5px;
      border-right-style: solid;
      border-left-style: solid;
      font-family: "PressStart2P-Regular";
    }
  
    @keyframes textShine {
      0% {
          background-position: 0% 50%;
      }
      100% {
          background-position: 100% 50%;
      }
  }
  
    .gameAnnouncementTitle {
      background: linear-gradient(
        to right,
        #b9001e 20%,
        #000000 30%,
        #000000 70%,
        #b9001e 80%
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    background-size: 500% auto;
    animation: textShine 5s ease-in-out infinite alternate;
    }
  
    .gameAnnouncementInblock {
      align-content: center;
      display: inline;
      align-items: center;
      justify-content: center;
      font-family: "PressStart2P-Regular";
    }

    .authorsContactComponentBlock {
      align-content: left;
      display: inline;
      align-items: left;
      justify-content: left;
      font-family: "PressStart2P-Regular";
    }

    .gameVideoBlockWrapperBlock {
      width: 100%;
      min-height: 20vh;
      background-color: #000000;
    }
    
    .gameAnnouncementVideoBlock {
      border-color: black;
      border-width: 10px;
      border-style: solid;
      width: 560px;
      height: 315px;
    }
  
    .gameAnnouncementDownloadBlock {
      align-content: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px;
    }
    
    .ourGamesBlock {
      color: white;
      min-height: 30vh;
      width: 50%;
      justify-content: center;
      display:flex;
      font-family: "PressStart2P-Regular";
    }
  
    .ourGamesGameBlock {
      vertical-align: top;
      min-width: 300px;
      max-width: 300px;
    }
  
    .ourGamesMainBlock {
      align-content: center;
      display: inline;
      align-items: center;
      justify-content: center;
    }
  
    .ourGamesMainBlockTitle {
      color: #b9001e;
      width: 50%;
      border-top-style: solid;
      border-width: 5px;
      border-color: #ffc000;
      font-family: "PressStart2P-Regular";
      margin-top: 5vh;
      margin-bottom: 5vh;
    }
  
    .ourGamesGameTitle {
      color: #ffc000;
    }

  
  }

  
  @media only screen and (max-width: 500px) {
    /* styles for devices with a maximum screen width of 1208px */
    .gamePageComponentDivider {
      min-height: 10vh;
  }
  
    .gameAnnouncementBlogBlockPara {
      color: black;
      margin: 5px;
      text-align: justify;
    }
    
    .gameAnnouncementBlock {
      align-content: center;
      background-color: #ffc000;
      color: black;
      min-height: 30vh;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-color: #ffc000;
      border-width: 0px;
      border-right-style: solid;
      border-left-style: solid;
      font-family: "PressStart2P-Regular";
    }

    .gameAnnouncementBlockTokio {
      align-content: center;
      background-color: #d8d8d8;
      color: black;
      min-height: 30vh;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-color: #d8d8d8;
      border-width: 0px;
      border-right-style: solid;
      border-left-style: solid;
      font-family: "PressStart2P-Regular";
    }

    .gameAnnouncementBlockSpaceZ {
      align-content: center;
      background-color: #6db5c2;
      color: black;
      min-height: 30vh;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-color: #6db5c2;
      border-width: 0px;
      border-right-style: solid;
      border-left-style: solid;
      font-family: "PressStart2P-Regular";
    }
  
    @keyframes textShine {
      0% {
          background-position: 0% 50%;
      }
      100% {
          background-position: 100% 50%;
      }
  }
  
    .gameAnnouncementTitle {
      background: linear-gradient(
        to right,
        #b9001e 20%,
        #000000 30%,
        #000000 70%,
        #b9001e 80%
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    background-size: 500% auto;
    animation: textShine 5s ease-in-out infinite alternate;
    }
  
    .gameAnnouncementInblock {
      align-content: center;
      display: inline;
      align-items: center;
      justify-content: center;
      font-family: "PressStart2P-Regular";
    }

    .authorsContactComponentBlock {
      align-content: left;
      display: inline;
      align-items: left;
      justify-content: left;
      font-family: "PressStart2P-Regular";
    }

    .gameVideoBlockWrapperBlock {
      width: 100%;
      min-height: 20vh;
      background-color: #000000;
    }
    
    .gameAnnouncementVideoBlock {
      border-color: black;
      border-width: 0px;
      border-style: solid;
      width: auto;
      height: auto;
    }
  
    .gameAnnouncementDownloadBlock {
      align-content: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px;
    }
    
    .ourGamesBlock {
      color: white;
      min-height: 30vh;
      width: 50%;
      justify-content: center;
      display:flex;
      font-family: "PressStart2P-Regular";
    }
  
    .ourGamesGameBlock {
      vertical-align: top;
      min-width: 300px;
      max-width: 300px;
    }
  
    .ourGamesMainBlock {
      align-content: center;
      display: inline;
      align-items: center;
      justify-content: center;
    }
  
    .ourGamesMainBlockTitle {
      color: #b9001e;
      width: 50%;
      border-top-style: solid;
      border-width: 5px;
      border-color: #ffc000;
      font-family: "PressStart2P-Regular";
      margin-top: 5vh;
      margin-bottom: 5vh;
    }
  
    .ourGamesGameTitle {
      color: #ffc000;
    }

  
  }